<template>
  <div class="container py-5">
    <p class="fw-bold">2024 AAD</p>
    <p>時間：2024/03/08-10</p>
    <p>地點：San Diego Convention Center</p>
    <p>展位：#2455</p>

    <p>
    2024 AAD<br>
    American Academy of Dermatology Association<br><br>
    We are looking forward to another great show! 
    <br>
    Next week we will be in San Diego at AAD doing live demos and sharing about our latest features within the Observ 520x. 
    <br>
    Will you be there? Visit us at booth #2455
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_36.jpg"
    />


  </div>
</template>
<script>
export default {};
</script>
